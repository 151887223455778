import React, { Component } from "react";
import { graphql } from 'gatsby';
import Layout from "../layouts";
import get from 'lodash/get';


export default class Resume extends Component {
  render() {
    const me = get(this.props.data, 'allContentfulPerson.edges[0].node');
    return (
      <Layout title="Resume" hideHeader>
        <article>
          <embed src={me.resume.file.url} type="application/pdf" />
        </article>
      </Layout>
    );
  }
}

export const resumeQuery = graphql`
  query ResumeQuery {
    allContentfulPerson(filter: { name: { eq: "Shamroy Pellew" }}) {
      edges {
        node {
          resume {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
